/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant
*/

let adminSection = [
    {
        header: 'Menu',
    },
    {
        title: 'Portfolio',
        route: { name: 'Portfolio' },
        icon: 'GridIcon',
        action: 'read',
        resource: 'parent',
    },
    {
        title: 'Portfolio Result',
        route: { name: 'Result' },
        icon: 'TrelloIcon',
        action: 'read',
        resource: 'parent',
    },
    {
        title: 'Company Analytics',
        route: { name: 'Company Analytics' },
        icon: 'CircleIcon',
        action: 'read',
        resource: 'parent',
    },
    {
        title:'Company',
        icon:'BriefcaseIcon',
        tagVariant: 'light-warning',
        children: [
            {
                title:'Company Home',
                route:{name: 'Company' },
            },
            {
                title:'Company Onboard',
                route:{name: 'Company Add' },
            },
        ]
        
    },
    {
        title:'Questionnaire',
        icon:'BookOpenIcon',
        tagVariant: 'light-warning',
        children: [
            {
                title:'Questionnaire Home',
                route:{name: 'Questionnaire' },
            },
            {
                title:'Create Questionnaire',
                route:{name: 'Questionnaire Create' },
            },
        ]
        
    },
    {
        title:'Users',
        icon:'UserIcon',
        children: [
            {
                title:'Home',
                route:{name: 'Users' },
                action: 'read',
                resource: 'child',
            },
            {
                title:'Add User',
                route:{name: 'Users Add' },
                action: 'read',
                resource: 'child',
            },
        ]
    },
    // {
    //     header: 'Main Section',
    //     action: 'read',
    //     resource: 'parent',
    // },
    
    // {
    //     title:'Companies',
    //     icon:'GitMergeIcon',
    //     children: [
    //         {
    //             title:'Company Home',
    //             route:{name: 'Companies Home' },
    //             action: 'read',
    //             resource: 'parent',
    //         },
    //         {
    //             title:'Add Company',
    //             route:{name: 'Company Add' },
    //             action: 'read',
    //             resource: 'parent',
    //         },
    //     ]
    // },
    
    // {
    //     header: 'Child Section',
    //     action: 'read',
    //     resource: 'child',
    // },
    // {
    //     title: 'Questionnaire Result',
    //     route: { name: 'Company Result' },
    //     icon: 'BarChart2Icon',
    //     action: 'read',
    //     resource: 'child',
    // },
    // {
    //     title: 'Questionnaire Fill',
    //     route: { name: 'Questionnaire Fill' },
    //     icon: 'Edit2Icon',
    //     action: 'read',
    //     resource: 'child',
    // }
]

let parentSection = [
    {
        header: 'Menu',
        action: 'read',
        resource: 'parent',
    },
    {
        title: 'Portfolio',
        route: { name: 'Portfolio' },
        icon: 'GridIcon',
        action: 'read',
        resource: 'parent',
    },
    {
        title: 'Portfolio Result',
        route: { name: 'Result' },
        icon: 'TrelloIcon',
        action: 'read',
        resource: 'parent',
    },
    {
        title: 'Company Analytics',
        route: { name: 'Company Analytics' },
        icon: 'CircleIcon',
        action: 'read',
        resource: 'parent',
    },
    {
        title:'Company',
        icon:'BriefcaseIcon',
        tagVariant: 'light-warning',
        children: [
            {
                title:'Company Home',
                route:{name: 'Company' },
                action: 'read',
                resource: 'parent',
            },
            {
                title:'Company Onboard',
                route:{name: 'Company Add' },
                action: 'read',
                resource: 'parent',
            },
        ]
        
    },
    {
        title:'Users',
        icon:'UserIcon',
        children: [
            {
                title:'Home',
                route:{name: 'Users' },
                action: 'read',
                resource: 'child',
            },
            {
                title:'Add User',
                route:{name: 'Users Add' },
                action: 'read',
                resource: 'child',
            },
        ]
    },
    // {
    //     header: 'Child Section',
    //     action: 'read',
    //     resource: 'child',
    // },
    // {
    //     title: 'Questionnaire Result',
    //     route: { name: 'Company Result' },
    //     icon: 'BarChart2Icon',
    //     action: 'read',
    //     resource: 'child',
    // },
    // {
    //     title: 'Questionnaire Fill',
    //     route: { name: 'Questionnaire Fill' },
    //     icon: 'Edit2Icon',
    //     action: 'read',
    //     resource: 'child',
    // }
]

let childSection = [
    {
        header: 'menu',
        action: 'read',
        resource: 'child',
    },
    {
        title: 'Questionnaire Result',
        route: { name: 'Company Result' },
        icon: 'BarChart2Icon',
        action: 'read',
        resource: 'child',
    },
    {
        title: 'Questionnaire Fill',
        route: { name: 'Questionnaire Fill' },
        icon: 'Edit2Icon',
        action: 'read',
        resource: 'child',
    }
]

// let commonSection = [
//     {
//         title:'Users',
//         icon:'UserIcon',
//         children: [
//             {
//                 title:'Home',
//                 route:{name: 'Users' },
//                 action: 'read',
//                 resource: 'child',
//             },
//             {
//                 title:'Add User',
//                 route:{name: 'Users Add' },
//                 action: 'read',
//                 resource: 'child',
//             },
//         ]
//     }
// ]

export {
    adminSection, 
    parentSection,  
    childSection, 
    // commonSection
};