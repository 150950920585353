<template>
  <div class="top-class">
    <div class="auth-wrapper background">
      <b-img
        :src="appIconDarkImage"
        class="top-left"
      />
      <div
        style="
          position: absolute;
          top: 12vw;
          left: 12vw;
          height:1px;
          width: 1px;
        "
        class="glow-box"
      ></div>
      <!-- <b-row> -->
        <b-col lg="6" md="8" sm="12" class="auth-inner text-white px-5">


          <!-- Forgot Password v1 -->
          <div class="mb-0">
            <!-- <b-link class="brand-logo">
              <vuexy-logo />

              <h2 class="brand-text text-primary ml-1">
                CyMAP
              </h2>
            </b-link> -->

            <!-- <b-card-title class="mb-1">
              Forgot Password? 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Enter your email and we'll send you instructions to reset your password
            </b-card-text> -->


            <div style="text-align:center">
              <h1 class="text-white">
                Forgot Password ?
              </h1>
              <p>
                Enter your email and we'll send you instructions to reset your password
              </p>
            </div>
            <!-- form -->
            <b-col xl="6" lg=8 offset-xl="3" offset-lg="2" class="mt-3">
              <validation-observer ref="simpleRules">
                <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="validationForm"
                >
                  <!-- email -->
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="forgot-password-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="forgot-password-email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit button -->
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    class="glow-box"
                  >
                    Send reset link
                  </b-button>
                </b-form>
              </validation-observer>
            </b-col>
            <b-card-text class="text-center mt-2">
              <b-link :to="{name:'auth-login'}">
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </b-card-text>

          </div>
        <!-- /Forgot Password v1 -->
        </b-col>
      <!-- </b-row> -->
      <div class="screenshot">
        <b-img src="/screenshot.png"></b-img>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BImg
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AxiosService from '@/Service/AxiosService'
import { $themeConfig } from '@themeConfig'
const { appName, appLogoImage, appLogoDarkImage, appIconImage, layout, appIconDarkImage, loginImage } = $themeConfig.app


export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow, 
    BCol,
    BImg
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      appName, appLogoImage, appLogoDarkImage, appIconImage, layout, appIconDarkImage, loginImage
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          AxiosService.post("/forgotPassword", {email:this.userEmail})

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email sent with reset link if user exists',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>

<style scoped>
.top-class{
  position: absolute; 
  height: 100vh; 
  width: 100%; 
  top: 0; 
  left: 0;
  background-color: #1955EB;
}
.background {
  margin: 4vw;
  border-radius: 50px;
  background-color: #00113B;
  align-items: center;
}
.top-left{
  position: absolute;
  top:  7vw;
  left: 7vw;
  height: 7vh;
  width: 7vh;
}
.glow-box {
  box-shadow: 0 0 350px 50px rgba(255, 255, 255, 0.2) !important;
}
.screenshot{
  position: absolute;
  right:4vw;
  top: 50%;
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
}
.screenshot img{
  height:70%;
  width: 60%;
  float:right
}
@media only screen and (max-width: 767px) {
  .screenshot{
    display: none;
  }
}
</style>
