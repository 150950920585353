<template>
  <b-card>
    <div class="title">
      {{ value.name }}
    </div>
    <div class="report-status">
      <div v-if="value.submitted && value.reportGenerated">
        Complete 100%
      </div>
      <div v-else-if="!value.submitted">
        Not submitted
      </div>
      <div v-else>
        Report generating
      </div>
    </div>
    
    <!-- <feather-icon v-if="value.submitted && value.reportGenerated"
        icon="DownloadIcon"
        class="top-right"
        size="24"
        @click="downloadReport(value)"
    /> -->
    <!-- <feather-icon v-else
        icon="DownloadIcon"
        class="top-right"
        size="24"
        :disabled="true"
    /> -->
    <div class="box-top mt-1">
      <!-- <div v-html="getLetterGrade(value.value)"></div> -->
      <div v-if="!value.submitted" class="circle not-submitted">?</div>
      <div v-else-if="value.value>3.9" class="circle A">A</div>
      <div v-else-if="value.value>3.6" class="circle B">B+</div>
      <div v-else-if="value.value>3.2" class="circle B">B</div>
      <div v-else-if="value.value>2.9" class="circle B">B-</div>
      <div v-else-if="value.value>2.6" class="circle C">C+</div>
      <div v-else-if="value.value>2.2" class="circle C">C</div>
      <div v-else-if="value.value>1.9" class="circle C">c-</div>
      <div v-else-if="value.value>1.6" class="circle D">D+</div>
      <div v-else-if="value.value>1.2" class="circle D">D</div>
      <div v-else-if="value.value>0.9" class="circle D">D-</div>
      <div v-else                      class="circle F">F</div>
    </div>
    <div class="portfolio-company text-center mt-1">
      
    </div>
    <!-- <div class="d-flex justify-content-around mt-1 bottom-button">
      <div v-if="value.submitted && value.reportGenerated">
        100% complete
      </div>
      <div v-else-if="!value.submitted">
        Not submitted
      </div>
      <div v-else>
        Report generating
      </div>
      <div class="results-link" @click="goToResultsPage(value, parentId)">
        review results
      </div>
    </div> -->

    <b-row>
      <b-col cols="6" :class="{'disabled': !value.submitted || !value.reportGenerated}">
        <div class="custom-pill" @click="goToResultsPage(value, parentId)">
          <feather-icon 
              icon="EditIcon"
              size="15"
              inline
          />
          Review Results
        </div>
        <!-- <b-button variant="primary" class="w-100 mt-1" @click="goToResultsPage(value, parentId)">Review results</b-button> -->
      </b-col>
      <b-col cols="6" @click="downloadReport(value)">
        <!-- <b-button variant="primary" class="w-100 mt-1" @click="downloadReport(value)">Download report</b-button> -->
        <div class="custom-pill">
          <feather-icon 
              icon="DownloadIcon"
              size="15"
              inline
          />
          Download Result
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AxiosService from '@/Service/AxiosService'
import Config from "@/Constants/Config.json"
import { BCard, BRow, BCol, BButton } from 'bootstrap-vue'
import Feather from '../../ui/feather/Feather.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    Feather
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    parentId:{
      type: String
    }
  },
  data() {
    return {
    }
  },
  // mounted(){
  //   console.log("prop", this.value, this.parentId);
  // },
  // watch:{
  //   value(val){
  //     console.log("val", val)
  //   }
  // },
  methods:{
    boxCss(value){
      // console.log("value", value)
      if(value<1) return "box-F"
      else return "box"
    },
    gradeCss(value){
      if(value<1) return "color: #FF0000"
      else return "color: #134270"
    },
    downloadReport(child){
      this.$store.dispatch("setLoader",true)

      AxiosService.downloadPdf(`/createChildReport?companyId=${child.id}`).then((response)=>{
        this.$store.dispatch("setLoader",false)
        // console.log("response", response)
        if(response.error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: response.error,
              },
          })
        return;
        }
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${child.name} Report.pdf`
        link.click()
      })
    },
    goToResultsPage(value, parentId){
      this.$router.push({name:"Result", params:{childId:value.id, parentId:parentId, grade:value.value}})
    }
  },
  computed:{
    backendUrl(){
      return Config.backendUrl;
    }
  }
}
</script>

<style scoped>
  
   .box-top{
    display:flex;
    justify-content: center;
  }
  
  /*
  .portfolio-company{
    font-size: 15px;
  }
  .results-link{
    cursor: pointer;
    text-decoration: underline;
  }
  .top-right {
    position: absolute;
    right:20px;
    top:10px;
    font-size: 20px;
    cursor:pointer;
  }
  .bottom-button{
    color:#545454 !important;
    font-family: 'Helvetica Now', Helvetica, Arial, serif;
  } */

  .title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .report-status {
    color: #9596A3;
  }
  .custom-pill{
    font-size: 9px;
    background-color: #E8EBEF;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
  }
  .disabled{
    pointer-events: none;
    opacity: 0.5;
  }
  .dark-layout .custom-pill{
    background-color: #343440;
  }
</style>