<template>
    <div>
			<e-charts
					v-if="result"
					ref="heatmap"
					autoresize
					:options="option"
					theme="theme-color"
					auto-resize
					@chartInitialized = "handleChild"
			/>
			<b-modal
				id="recommendationModal"
				scrollable
				centered
				size="xl"
				title="Recommendation"
				hide-footer
			>
				<b-card-text v-if="recommendation && recommendation.recommendations && recommendation.recommendations.length>0">
					<!-- {{ value.name }} -->
					<div class="d-flex" style="justify-content: space-evenly; font-weight: bold">
					<div>Question</div>
					<div>Answer</div>
					</div>
					<app-collapse>
						<div v-for="(recommendation, index) in recommendation.recommendations" :key="index"> 
							<app-collapse-item :question="recommendation.question" :answer="recommendation.answer" :grade="recommendation.grade">
							<div>
								{{ recommendation.recommendation }}
							</div>
							</app-collapse-item>
						</div>
					</app-collapse>
				</b-card-text>
				<b-card-text v-else>
					You're all set
				</b-card-text>
			</b-modal>
    </div>
</template>
  
<script>
  import ECharts from '@core/components/charts/echart/EChart.vue'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/chart/heatmap'
  import 'echarts/lib/component/grid'
  import 'echarts/lib/component/visualMap'
  import theme from '@core/components/charts/echart/theme.json'
  import Functions from '@/Constants/Functions'
  ECharts.registerTheme('theme-color', theme)

  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
	import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
	import AxiosService from '@/Service/AxiosService'
	import { BModal, VBModal, BCardText  } from 'bootstrap-vue'

  export default {
    components: {
      ECharts,
			AppCollapse,
			AppCollapseItem,
			BModal,
			VBModal,
			BCardText
    },
    props: {
      result: {
        type: Object,
        required:true
      }
    },
    data() {
      return {
        option:{},
        myChart:null,
				recommendation:null
      }
    },
    methods:{
      handleChild(chart){
        this.myChart = chart;
      },
      getOption(){
        this.option =  {
          tooltip: {
            position: 'top'
          },
          gridColor:[
            '#0000CC',
            '#008000',
            '#FFD700',
            '#FFA500',
            '#FF0000'
          ],
          grid: {
            height: '50%',
            top: '10%'
          },
          xAxis: {
            type: 'category',
            data: this.result.categoryNames,
            splitArea: {
              show: true
            },
            axisLabel: {
              show: true,
              interval: 0,
              rotate: -45,
            },
          },
          yAxis: {
            type: 'category',
            data: this.result.companyNames,
            splitArea: {
              show: true
            }
          },
          visualMap: {
            type:'piecewise',
            min: 0,
            max: 4,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            pieces: [
              { min: 3.9, color: '#1375E5', label:'A' },  

              { min: 3.6, max: 3.9, color: '#5FE193', label:'B+' },
              { min: 3.2, max: 3.6, color: '#5FE193', label:'B'  },
              { min: 2.9, max: 3.2, color: '#5FE193', label:'B-' },
              
              { min: 2.6, max: 2.9, color: '#FFA500', label:'C+' },
              { min: 2.2, max: 2.6, color: '#FFA500', label:'C'  },
              { min: 1.9, max: 2.2, color: '#FFA500', label:'C-' },   
              
              { min: 1.6, max: 1.9, color: '#E1DB5F', label:'D+' },
              { min: 1.2, max: 1.6, color: '#E1DB5F', label:'D'  },
              { min: 0.9, max: 1.2, color: '#E1DB5F', label:'D-' },

              { min: 0, max: 0.9, color: '#FF0000', label:'F' }              
            ],
          },
          series: [
            {
              name: 'Score',
              type: 'heatmap',
              data: this.result.result,
              label: {
                show: true,
                formatter: function(d) {
                  return Functions.getGrade(d.value[2])
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
      } 
    },
    mounted(){
        // console.log("heatmap mounted >>>>>>>..", this.result)
        this.getOption();
    },
    watch:{
      myChart(){
        if(!this.myChart)
					return;
		
				this.myChart.on('click', params => {
					// console.log("params", params.value)
					// console.log(this.result.categoryNames[params.value[0]])
					// console.log(this.result.companyNames[params.value[1]])
					// console.log(this.result.companyIds[params.value[1]])


					AxiosService.get("/getChildCompanyCategoryRecommendation", {
						child: this.result.companyIds[params.value[1]],
						category: this.result.categoryNames[params.value[0]] 
					}).then(({error, message, data})=>{
						if(!data){
								this.$toast({
								component: ToastificationContent,
								position: 'top-right',
								props: {
										variant: 'error',
										text: `Some error occured while getting companies, Please try again or contact an admin`,
								},
								})
						}
						if(error){
								this.error = error;
								this.recommendation = null;
						}
						else {
								this.error = null;
								this.recommendation = data
								this.$bvModal.show("recommendationModal")
						}
					})  
				});
    	}
  	}
  }
</script>
  
<style>
  .echarts {
    width: 100% !important;
    height: 600px !important;
  }
</style>
  