<template>
    <div>
      <b-card>
        <div class="title mb-4 p-1">
          <b-row>
            <b-col cols="5">
              {{ company }} Score
            </b-col>
            <b-col cols="1"> 
              <div class="legend current"></div>
            </b-col>
            <b-col cols="4">
              Portfolio Average
            </b-col>
            <b-col cols="1">
              <div class="legend average"></div>
            </b-col>
          </b-row>
        </div>
        <div class="inner-card">
          <b-row v-for="(indicator,index) in indicator" :key="index">
            <b-col cols="4">
              {{ indicator.name }}
            </b-col>
            <b-col cols="8">
            <div class="progress orange">
                <div class="progress-bar current" :style="setWidth(value[0].value[index])">
                    <div class="progress-value current" :id="indicator.name+index">{{ Number(( value[0].value[index]/50*100).toFixed(2)) }}%</div>
                    <b-tooltip
                        :target="indicator.name+index"
                        placement="top"
                        triggers="hover"
                    >
                        <div>
                            <span font-weight="bold"> {{ company }} Score </span> 
                            <span>: {{Number(( value[0].value[index]/50*100).toFixed(2))}}%</span>
                        </div>
                        <!-- {{data.result[companyName][category]}} -->
                    </b-tooltip>
                </div>
                <div class="progress-bar" :style="setWidth(value[1].value[index])">
                    <div class="progress-value average" :id="'average'+index">{{ Number(( value[1].value[index]/50*100).toFixed(2)) }}%</div>
                    <b-tooltip
                        :target="'average'+index"
                        placement="top"
                        triggers="hover"
                    >
                        <div>
                            <span font-weight="bold"> Portfolio Average </span> 
                            <span>: {{Number(( value[1].value[index]/50*100).toFixed(2))}}%</span>
                        </div>
                        <!-- {{data.result[companyName][category]}} -->
                    </b-tooltip>
                </div>
            </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
</template>
  
<script>
  // import Functions from "@/Constants/Functions"
  import { VBTooltip, BTooltip, BCard, BProgressBar, BProgress, BCol,BRow } from 'bootstrap-vue'
  
  export default {
    components: {
      BProgressBar,
      BProgress,
      BCard,
      BCol,
      BRow,
      BTooltip
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props: {
      value: {
        type:Array
      },
      indicator:{
        type:Array
      },
      company:{
        type:String
      }
    },
    methods: {
      setWidth(value){
        return "width:"+(value/50*100)+"%"
      }
    }
  }
</script>
  
<style>
.title{
  font-size: 16px;
  font-weight: 700;
  color: #333;
}

.dark-layout .title{
  color: #fff;
}

.inner-card{
  background-color: #E8EBEF;
  padding:30px;
  border-radius: 20px;
  font-size: 10px;
  margin: -10px;
}

.dark-layout .inner-card{
  background-color: #343440;
}

.progress-title{
  font-size: 18px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  margin: 0 0 25px;
}
.progress{
  height: 15px;
  background: #D8DCDB;
  border-radius: 15px;
  margin-bottom: 30px;
  overflow: visible;
  position: relative;
}

.progress:after{
  border: 7px solid #e9e9ea;
  left: auto;
  right: 0;
}
.progress .progress-bar{
  box-shadow: none;
  border: none;
  border-radius: 15px;
  position: absolute;
  -webkit-animation: animate-positive 1s;
  animation: animate-positive 1s;
  overflow: visible;
}
.progress .progress-bar.current{
  height: 15px;
  background:#28B684 !important; 
  border-radius: 15px;
}

.progress-value{
  width: 30px;
  height:30px;
  line-height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 7px;
  font-weight: 600;
  position: absolute;
  top: -8px;
  right: 0;
  color: #fff;
  padding:2px;
  cursor: pointer;
}

.progress-value.current{
    background-color: #28B684;
}
.progress-value.average{
  background-color: #E2B355;
  z-index:2;
}
@-webkit-keyframes animate-positive{
  0%{ width: 0; }
}
@keyframes animate-positive{
  0%{ width: 0; }
}
.legend{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: -25px;
  margin-top:5px;
}
.legend.current{
  background-color: #28B684;
}
.legend.average{
  background-color: #E2B355;
}
</style>
  