<template>
  <b-col cols="12" style="font-size:11px !important">
    <b-row class="analytics-header">

      <b-col cols="12"
        v-if="$can('manage', 'admin')"
      >
        <div>
            <b-form-group>
            <v-select
              v-model="parent"
              label="name"
              :options="parentCompanies"
              style="border-radius: 10px;"
            />
          </b-form-group>
        </div>
      </b-col>

    </b-row>
  <b-row v-if="data" :key="data._id">
    <!-- <b-col  cols="12"
      v-if="$can('manage', 'admin')"
    >
      <div>
        PE firm
          <b-form-group>
          <v-select
            v-model="parent"
            label="name"
            :options="parentCompanies"
            
          />
        </b-form-group>
      </div>
    </b-col> -->
    <b-col md="6" sm = "12">
      <b-card v-if="data && data.childAverage" title="Average Score by Company" >
        <rose-chart :value="data.childAverage" :key="data._id"/>
      </b-card>
      <div class="average" :key="data._id">
        {{ Math.round(10*data.childTotal)/10 }}
      </div>
    </b-col>
    <b-col md="6" sm = "12">
      <b-card v-if="data && data.categoryAverage" title="Average Score by Category" >
        <doughnut-chart :value="data.categoryAverage" :key="data._id"/>
      </b-card>
      <!-- <div class="average" :key="data._id">
        {{ Math.round(10*data.categoryTotal)/10}}
      </div> -->
    </b-col>
    <b-col cols="12">
      <average-scores :value="data.children" :key="data._id"/>
    </b-col>
  </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol, BFormGroup, BCard } from 'bootstrap-vue'

// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
// import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
// import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import AverageScores from '../AverageScores.vue'
import vSelect from 'vue-select'
import RoseChart from '@/layouts/components/chart/rose-chart.vue'
import DoughnutChart from '@/layouts/components/chart/doughnut-chart.vue'
import AxiosService from '@/Service/AxiosService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    // AnalyticsCongratulation,
    // AnalyticsAvgSessions,
    // StatisticCardWithAreaChart,
    // AnalyticsSupportTracker,
    // AnalyticsTimeline,
    // AnalyticsSalesRadarChart,
    // AnalyticsAppDesign,
    // InvoiceList,
    AverageScores,
    vSelect,
    BFormGroup,
    RoseChart,
    DoughnutChart
  },
  data() {
    return {
      data:null,
      parentCompanies:[],
      parent:null,
    }
  },
  methods:{
    init(){
      if(this.user.role == "admin")
        this.getParentCompany()
      else if(this.user.role=='parent')
        this.parent = this.user.entity;
    },
    getParentCompany(){
      AxiosService.get("/companies", {"type":"parent"}).then(({error, data})=>{
        if(error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: `Some error occured while getting companies, Please try again or contact an admin`,
              },
          })
        }
        else {
          this.parentCompanies = data.rows;
          this.parent = data.rows[0];
        }
      })
    },
    getGrade(value){
      let grade = null
      if(value >3.9) grade = "A"
      else if(value>3.6) grade="B+"
      else if(value>3.2) grade="B"
      else if(value>2.9) grade="B-"
      else if(value>2.6) grade="C+"
      else if(value>2.2) grade="C"
      else if(value>1.9) grade="C-"
      else if(value>1.6) grade="D+"
      else if(value>1.2) grade="D"
      else if(value>0.9) grade="D-"
      else grade="F"

      return grade
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
  watch:{
    parent(newParent){
      AxiosService.get("/getCompanyAnalytics/", {parent:newParent._id}).then(({error, data})=>{
        // console.log("data", data)
        if(error) {
          // console.log("error", error)
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: `Some error occured while getting analytics data, Please try again or contact an admin`,
              },
          })
        }
        else{
          this.data = data;

          
          //childAverage
          if(data.childAverage && data.childAverage.length>0)
            data.childAverage.forEach((e)=>{
              e.grade = this.getGrade(e.value)
            })

          //category average
          if(data.categoryAverage && data.categoryAverage.length>0)
            data.categoryAverage.forEach((e)=>{
              e.grade = this.getGrade(e.value)
            })


          //children
          if(data.children && data.children.series && data.children.series.length>0){
            let gradeFuntion = this.getGrade
            data.children.series.forEach((e)=>{
                // let gradeList = [];
                // e.data.forEach((value)=>{
                //   value = {data:value, grade:this.getGrade(value)}
                //   // gradeList.push(this.getGrade(value))
                // })
                // let grade = this.getGrade(e.data[0])
                  
                e.label = {
                  show: true,
                  position: 'inside',
                  formatter : function (data){
                    // console.log("Data", data)
                    return  gradeFuntion(data.data)
                  },
                }
              })
            }
          }
      })
    },
    user(newVal){
      // console.log("user watch")
      if(newVal)
        this.init();
    }
  },
  computed:{
    user(){
        return this.$store.getters.getUser || {};
    }
  }
}
</script>

<style scoped>
  .average{
    position: absolute;
    left: calc(50% - 15px);
    top: 40%;
    z-index: 99;
    font-size: 20px;
    font-weight: bold;
  }
</style>