<template>
    <b-card v-if="data && !error" >
        <!-- <heatmapChart v-if="data && !error" :result="data"/> -->
        <table width="100%">
            <tr>
                <th> </th>
                <th v-for="category in data.categoryNames" :key="category" class="company-container" :id="category">
                    <div class="company-names">
                        {{category}}
                    </div>
                    <b-tooltip
                        :target="category"
                        placement="top"
                        triggers="hover"
                    >
                        {{ category }}
                    </b-tooltip>
                </th>
            </tr>
            <tr v-for="companyName in data.companyNames" :key="companyName" class="category-container">
                <th :id="companyName">{{companyName}}</th>
                <b-tooltip
                    :target="companyName"
                    placement="top"
                    triggers="hover"
                >
                    {{ companyName }}
                </b-tooltip>
                <td v-for="category in data.categoryNames" :key="category" class="has-content" :class="getGrade(data.result[companyName][category], true)" :id="companyName+category" >
                    
                    <div class="grade-value">
                        {{getGrade(data.result[companyName][category])}}
                    </div>

                    <b-tooltip
                        :target="companyName+category"
                        placement="top"
                        triggers="hover"
                    >
                        <div>
                            <span font-weight="bold">{{companyName}} / {{category}} </span> 
                            <span>: {{data.result[companyName][category]}}</span>
                        </div>
                        <!-- {{data.result[companyName][category]}} -->
                    </b-tooltip>
                </td>
                
                <!-- <td>{{ company.name }}</td>
                <td>{{ company.category }}</td>
                <td>{{ company.average }}</td> -->
            </tr>
        </table>
    </b-card>
    
</template>

<script>
import { VBTooltip, BTooltip, BRow, BCol, BCard } from 'bootstrap-vue'
import AxiosService from '@/Service/AxiosService'
import heatmapChart from '@/layouts/components/chart/heatmap-chart.vue'
import Functions from '@/Constants/Functions'

export default {
    components: {
        heatmapChart,
        BRow,
        BCol,
        BCard,
        BTooltip
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props:{
        parentId:{
            type:String,
            required:true
        }
    },
    mounted(){
        this.getParentCompanyResult()
    },
    data(){
        return {
            data:null,
            error:null,
        }
    },
    methods:{
        getParentCompanyResult(){
            AxiosService.get("/getParentCompanyResult", {parent:this.parentId}).then(({error, message, data})=>{
                if(!data){
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        variant: 'error',
                        text: `Some error occured while getting companies, Please try again or contact an admin`,
                    },
                    })
                }
                if(error){
                    this.error = error;
                    this.data = null;
                }
                else {
                    this.error = null;
                    this.data = data
                }
            })    
        },
        // getGrade(value){
        //     let grade = null;
        //     if(value=='-') grade = "Z"
        //     else if(value >3.9) grade = "A"
        //     else if(value>2.9) grade="B"
        //     else if(value>1.9) grade="C"
        //     else if(value>0.9) grade="D"
        //     else grade="F"
        //     return grade;
        // }

        getGrade(value, isCss){
            if(!value || value=='-'){
                if(isCss) return "Z"
                else return "-"
            }
            let grade = Functions.getGrade(value)
            if(!grade) return "Z"
            if(isCss) return grade[0]
            return grade
        },
    }
}
</script>

<style scoped>
th{
    padding:10px;
}
.category-container th{
    background:#E8EBEF;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding:10px 0 10px 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
}
.dark-layout .category-container th{
    background:#343440;
}
.company-names{
    text-align: center;
    border-radius: 20px;
    background: #4E4994;
    padding: 10px;
    color:white;

    /** text-overflow: ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;

  /* display: -webkit-box;          
  -webkit-line-clamp: 4;         
  line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;             
  text-overflow: ellipsis;       
  height: calc( 1.3rem * 4);     
  word-break: break-word;       

  text-align: center;            
  vertical-align: middle;        
  justify-content: center;       
  align-items: center;           
  text-align: center;           
  vertical-align: center;
  position: relative; */
}
.company-container{
    background:#F6F7FA;
    padding:5px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;    
}
.dark-layout .company-container{
    background: #343440;
}
table {
  border-spacing: 3px;
  border-collapse: separate; 
  table-layout: fixed; 
  cursor : pointer;
}
.grade-value{
    color: black;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: white;
}
</style>