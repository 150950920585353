<template>
  <b-card class="card-portfolio">
    <div class="about">
      {{ value.about || `About ${value.name}`  }}
    </div>
    <b-row class="main-content">
      <b-col cols="9">
        <div class="portfolio-company text-left">
          <div class="title">
            <div style="position: absolute; bottom: 0;">
              {{ value.name }}
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-between mt-2 bottom-button">
              <div class="results-link" @click="openModal">
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                />
                Recommendations
              </div>

              <div class="about-button">
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                />
                About 
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="3">
        <!-- <div> -->
          <!-- <div v-html="getLetterGrade(value.value)"></div> -->
          <div v-if="!value.average" class="circle not-submitted">?</div>
          <div v-else-if="value.average>3.9" class="circle A">A</div>
          <div v-else-if="value.average>3.6" class="circle B">B+</div>
          <div v-else-if="value.average>3.2" class="circle B">B</div>
          <div v-else-if="value.average>2.9" class="circle B">B-</div>
          <div v-else-if="value.average>2.6" class="circle C">C+</div>
          <div v-else-if="value.average>2.2" class="circle C">C</div>
          <div v-else-if="value.average>1.9" class="circle C">c-</div>
          <div v-else-if="value.average>1.6" class="circle D">D+</div>
          <div v-else-if="value.average>1.2" class="circle D">D</div>
          <div v-else-if="value.average>0.9" class="circle D">D-</div>
          <div v-else                        class="circle F">F</div>
        <!-- </div> -->
      </b-col>
    </b-row>

    <b-modal
      :id=value.name
      scrollable
      centered
      size="xl"
      title="Recommendations"
      hide-footer
    >
      <b-card-text class="modal-size-fix" v-if="value.recommendations && value.recommendations.length>0">
        <!-- {{ value.name }} -->
        <!-- <div class="d-flex" style="justify-content: space-evenly; font-weight: bold">
          <div>Question</div>
          <div>Answer</div>
        </div> -->
        <div v-for="(recommendation, index) in value.recommendations" :key="index" class="ml-2 mr-2 mt-1 mb-1"> 
          <!-- <app-collapse-item :question="recommendation.question" :answer="recommendation.answer" :grade="recommendation.grade">
            <div>
              {{ recommendation.recommendation }}
            </div>
          </app-collapse-item> -->
          
          <div :key="recommendation.question">
            <b-row>
              <b-col cols="10">
                <div class="question">
                  {{ recommendation.question }}
                </div>
              </b-col>
              <b-col cols="2">
                <feather-icon
                  :id="'recommendation-' + index"
                  icon="UserIcon"
                  style="color:#1954EC; cursor:pointer;"
                  size="16">
                </feather-icon>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <div class="answer">
                  {{ recommendation.recommendation }}
                </div>
              </b-col>
            </b-row>
            <b-tooltip
              :target="'recommendation-' + index"
              placement="left"
              triggers="hover"
            >
            <div v-if="typeof recommendation.answer=='string'">
              {{ recommendation.answer }}
            </div>
            <div v-else>
              <ul>
                <li v-for="(item, index) in recommendation.answer" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
          </b-tooltip>
          </div>
        </div>
      </b-card-text>
      <b-card-text v-else>
        You're all set
      </b-card-text>
    </b-modal>
  </b-card>
    
</template>

<script>
import AxiosService from '@/Service/AxiosService'
import Config from "@/Constants/Config.json"
import { VBTooltip, BTooltip, BModal, VBModal, BCardText, BCard, BRow, BCol  } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCardText,
    BModal,
    VBModal,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BCard,
    FeatherIcon,
    VBTooltip,
    BTooltip
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    parentId:{
      type: String
    }
  },
  data() {
    return {
    }
  },
  mounted(){
    // console.log("el", this.$el.querySelector('.about-button'))
    let parent = this.$el;

    parent.querySelector('.about-button').addEventListener('mouseenter', function() {
      parent.querySelector('.about').classList.add('about-show');
      parent.querySelector('.main-content').classList.add('opaque');
      
    })
    this.$el.querySelector('.about-button').addEventListener('mouseleave', function() {
      parent.querySelector('.about').classList.remove('about-show');
      parent.querySelector('.main-content').classList.remove('opaque');
    })
    
    
  },
  // watch:{
  //   value(val){
  //     console.log("val", val)
  //   }
  // },
  methods:{
    openModal(){
      this.$bvModal.show(this.value.name)
    },
    boxCss(value){
      // console.log("value", value)
      if(value<1) return "box-F"
      else return "box"
    },
    // gradeCss(value){
    //   if(value<1) return "color: #FF0000"
    //   else return "color: #134270"
    // },
    downloadReport(child){
      this.$store.dispatch("setLoader",true)

      AxiosService.downloadPdf(`/createChildReport?companyId=${child.id}`).then((response)=>{
        this.$store.dispatch("setLoader",false)
        // console.log("response", response)
        if(response.error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: response.error,
              },
          })
        return;
        }
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${child.name} Report.pdf`
        link.click()
      })
    },
    goToResultsPage(value, parentId){
      this.$router.push({name:"Result", params:{childId:value.id, parentId:parentId, grade:value.value}})
    }
  },
  computed:{
    backendUrl(){
      return Config.backendUrl;
    }
  }
}
</script>

<style scoped>
  .card-portfolio{
    height:110px;
    margin-bottom: 10px;
  }
  .title{
    font-weight: 700;
    font-size: 13px;
    height: 40px;
    position: relative;
  }
  .not-submitted{
    color: #aaa;
    border: 2px solid #aaa;
  }
  .results-link{
    cursor: pointer;
  }
  .top-right {
    position: absolute;
    right:20px;
    top:10px;
    font-size: 20px;
    cursor:pointer;
  }
  .about{
    transform: translateY(100%);
    opacity: 0;
    transition: 0.3s ease-in-out;
    position:absolute;
    height: 100%;
    width: 100%;
    padding:10%
  }
  .about-show {
    transform: translateY(0);
    opacity: 1;
  }
  .about-button{
    cursor:pointer;
  }
  .opaque{
    opacity: 10%;
  }
  .bottom-button {
    color:#545454 !important;
    font-size: 10px;
  }
  @media only screen and (min-width: 992px) {
    .circle{
      height:4vw;
      width:4vw;
      margin-left: -0.5vw;
    }
  }
  @media only screen and (min-width: 1300px) {
    .bottom-button {
      font-size: 12px;
    }
  }
  
  .question{
    font-weight: 500;
    font-size: larger;
  }
  .answer {
    font-weight: 400;
    font-size: medium;
    color:#91919A
  }
  ul{
    margin:0;
  }
  .modal-size-fix{
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>