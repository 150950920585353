<template>
  <div class="top-class">
    <div class="auth-wrapper background">
      <b-img
        :src="appIconDarkImage"
        class="top-left"
      />
      <div
        style="
          position: absolute;
          top: 12vw;
          left: 12vw;
          height:1px;
          width: 1px;
        "
        class="glow-box"
      ></div>
      <!-- <b-row> -->
        <b-col lg="6" md="8" sm="12"  class="auth-inner text-white px-5">

        <!-- Login v1 -->
        <div class="mb-0">
          <!-- <b-link class="brand-logo"> -->
            <!-- <b-img
              :src="loginImage"
              alt="logo"
            /> -->
            <div style="text-align:center">
              <h1 class="text-white">
                Welcome to SwanScreen
              </h1>
              <p>
                Streamline M&A cyber due diligence with SwanScreen, BlackSwan's cyber screening platform - rapidly assess a target's cybersecurity maturity and bechmarking it against your portfolio's overall posture.
              </p>
            </div>
          <!-- </b-link> -->
          
          <b-col xl="6" lg=8 offset-xl="3" offset-lg="2" class="mt-3">
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >

                <!-- email -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="userEmail"
                      name="login-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                      autofocus
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password"></label>
                    <b-link :to="{name:'auth-forgot-password'}" style="margin-bottom:2px">
                      <small class="text-white">Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="login-password"
                        placeholder="******"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group> -->

                <!-- submit button -->
                <b-button
                  variant="primary"
                  type="submit"
                  block
                  :disabled="invalid"
                  class="glow-box"
                >
                  Log in
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register-v1'}">
              <span>Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social button -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              href="javascript:void(0)"
              variant="facebook"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              href="javascript:void(0)"
              variant="twitter"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              href="javascript:void(0)"
              variant="google"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              href="javascript:void(0)"
              variant="github"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </div>

        <!-- /Login v1 -->
        </b-col>
      <!-- </b-row> -->
      <div class="screenshot">
        <b-img src="/screenshot.png"></b-img>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BImg, BRow, BCol
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
const { appName, appLogoImage, appLogoDarkImage, appIconImage, layout, appIconDarkImage, loginImage } = $themeConfig.app


export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BImg, 
    BRow, 
    BCol
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      appName, appLogoImage, appLogoDarkImage, appIconImage, layout, appIconDarkImage, loginImage
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods:{
    login() {
      let store = this.$store;
      // console.log("store", store)
      this.$refs.loginForm.validate().then(success => {
        if (success) {

          store.dispatch("login", {
            email: this.userEmail,
            password: this.password,
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.top-class{
  position: absolute; 
  height: 100vh; 
  width: 100%; 
  top: 0; 
  left: 0;
  background-color: #1955EB;
}
.background {
  margin: 4vw;
  border-radius: 50px;
  background-color: #00113B;
  align-items: center;
}
.top-left{
  position: absolute;
  top:  7vw;
  left: 7vw;
  height: 7vh;
  width: 7vh;
}
.glow-box {
  box-shadow: 0 0 350px 70px rgba(255, 255, 255, 0.2) !important;
}
.screenshot{
  position: absolute;
  right:4vw;
  top: 50%;
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
}
.screenshot img{
  height:70%;
  width: 60%;
  float:right
}
@media only screen and (max-width: 767px) {
  .screenshot{
    display: none;
  }
}
</style>
